.b-home {
  display: grid;
  grid-template-rows: 100px 1fr;
  background-color: #f5f7ff;
  overflow: auto;
  height: 100%;
}

.b-nav {
  height: 32px;
  padding: 8px 16px;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.b-home__main {
}

.b-home__icon {
  width: 200px;
  height: 75px;
}

.b-home__icon-error {
  display: flex;
  justify-content: center;
  align-items: center;
}
