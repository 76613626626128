.b-plg {
  height: 100%;
  overflow: auto;
}

.b-plg__container {
  width: 600px;
  margin: auto;
}

.b-plg__links {
  display: grid;
  grid-gap: 24px;
  grid-auto-flow: row;
}

.b-plg__link {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 8px;
  border-radius: 7px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: rgb(255, 255, 255);
}

.b-plg__link-main {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.b-plg__link-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f2e8;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.b-plg__link-body {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.b-plg__link-body > a {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
